import React from "react";
import "./App.css";
import "./CardCharts/index.js"
import CardCharts from "./CardCharts/index.js";
import Header from "./Header/index.js";

class App extends React.Component{

  update(){
    window.location.reload(false)
  }

  componentDidMount(){
    setInterval(this.update, 60000);
  }
  render(){   
    return(
    <>
    <Header className="Header"/>
    <div className="Content">
    <CardCharts/>
    </div>
    </>
    );
  }
}

export default App;
