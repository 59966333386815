import React from "react";
import { Card } from "react-bootstrap";
import mundo from "./../../../assets/mundo.svg";
import "./Cards.css";

class HeaderLink extends React.Component{
    render(){
        return(
            <Card.Header bsPrefix="Card-Header">
                <img src={mundo} alt="icone link" className="icon"></img>
                {this.props.children}
            </Card.Header>
        )
    }
}

export default HeaderLink;