import React from "react";
import { Card } from "react-bootstrap";
import tempoPassado from "./../../../assets/tempo-passado.svg"
import "./Footer.css"


class Footer extends React.Component{
    render(){
        return(
            <Card.Footer bsPrefix="Card-Footer">
                <img src={ tempoPassado } alt="icone de tempo" />
                <p className="Last-Update">Ultima Atualização: {this.props.children }</p>
            </Card.Footer>
        )
    }
}

export default Footer;