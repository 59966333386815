import React from "react";
import CTIC1 from "../../assets/CTIC1.png"
import UFPA from "../../assets/UFPA1.png"
import "./Header.css"

class Header extends React.Component{
    render(){
    return(
        <div className="Container">
            <div className="Header">
                <div className="UFPA">
                    <a href="https://portal.ufpa.br" target='_blank' rel="noreferrer">
                    <img className="UFPA_Logo" src={UFPA} alt="Logo da Universidade federal do Pará"/>
                    </a>
                        <section className="Text">
                            <h1>Universidade Federal do Pará</h1>
                            <h3>Disponibilidade de Serviços</h3>
                        </section>
                </div>  
                <div className="CTIC">
                    <img className="CTIC_Logo" src={CTIC1} alt="Logo do CTIC"/> 
                </div>    
            </div>
        </div>
    );
    }
}
export default Header;

