import React from "react";
import { Card } from "react-bootstrap";
import navegador from "./../../../assets/navegador.svg";
import "./Cards.css" ;

class HeaderWeb extends React.Component{
    render(){
        return(
            <Card.Header bsPrefix="Card-Header">
                <img src={navegador} alt="icone link" className="icon"></img>
                {this.props.children}
            </Card.Header>
        )
    }
}

export default HeaderWeb;