const axios = require('axios').default;

//Requisição que busca todos os items da API Zabbix a partir do Parâmetro application("string")
async function getItems(application) {
    const response = await axios.post(process.env.REACT_APP_API_URL, {
        "jsonrpc": "2.0",
        "method": "item.get",
        "params":
        {
            "output": [
                "name",
                "lastvalue",
                "description",
                "lastclock"
            ],
            "application": application,
            "filter": {
                "hostid": 10084
            },
            "sortfield": "name"
        },
        "id": 1,
        "auth": process.env.REACT_APP_API_KEY
    })
    return response;
}

export default getItems;