import React from "react";
import { Card } from "react-bootstrap"
import "./CardCharts.css";
import getItems from "./../../services/api.js"
import On from "./Status/On"
import Off from "./Status/Off"
import Footer from "./Footer/Footer";
import HeaderWeb from "./Headers/HeaderWeb";
import HeaderLink from "./Headers/HeaderLink";
import formatTime from "../../services/formatime";

class CardCharts extends React.Component{
    state={
        itensAcad: [],
        itensAdmin: [],
        itensLinks: []
    }
    //Quando montado o componente a requisição retorna os itens no formato necessário
    async componentDidMount(){
        const responseAcad = await getItems("Academico");
        this.setState({ itensAcad: responseAcad.data.result})
        const responseAdmin = await getItems("Administrativo");
        this.setState({ itensAdmin: responseAdmin.data.result})
        const responseLink = await getItems("Link de Internet");
        this.setState({ itensLinks: responseLink.data.result})
    }

    render(){
        // Definindo os arrays acima como estado
        const { itensAcad, itensAdmin, itensLinks } = this.state;


        return(
            <>
            <div className="itemsContainer">
            <h1 className="title">Serviços Acadêmicos</h1>
            {itensAcad.map(item =>(
                <Card key={item.itemid} bsPrefix="Card">
                    <a className="web-btn" href={item.description} target="_blank" rel="noreferrer">
                    <HeaderWeb>{item.name}</HeaderWeb>
                    <Card.Body>
                        {
                            // eslint-disable-next-line eqeqeq
                            item.lastvalue == 1 
                            ?(<On />)
                            :
                            (<Off />)
                        }
                    </Card.Body>
                    <Footer>{[formatTime(item.lastclock)]}</Footer>
                    </a>
                </Card>
            ))}
            <h1 className="title">Serviços Administrativos</h1>
            {itensAdmin.map(item =>(
                <Card key={item.itemid} bsPrefix="Card">
                    <a className="web-btn" href={item.description} target="_blank" rel="noreferrer">
                    <HeaderWeb>{item.name}</HeaderWeb>
                    <Card.Body>
                        {
                            // eslint-disable-next-line eqeqeq
                            item.lastvalue == 1 
                            ?(<On />)
                            :
                            (<Off />)
                        }
                    </Card.Body>
                    <Footer>{[formatTime(item.lastclock)]}</Footer>
                </a>
                </Card>
            ))} 
            <h1 className="title">Links de Internet</h1>
            {itensLinks.map(item =>(
                <Card key={item.itemid} bsPrefix="Card">
                    <HeaderLink>{item.name}</HeaderLink>
                    <Card.Body bsPrefix="Card-Link-Body">
                        {
                            // eslint-disable-next-line eqeqeq
                            item.lastvalue == 1
                            ?(<On />)
                            :(<Off />)
                        }
                    </Card.Body>
                    <Footer>{formatTime(item.lastclock)}</Footer>
                </Card>
            ))} 
            </div>
            </>
        );
    }
}


export default CardCharts;