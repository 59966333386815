import React from "react";
import sinalVazio from "./../../../assets/sinal-vazio.svg";
import "./Status.css";

class Off extends React.Component{
    render(){
        return(
            <div className="Status-Off">
                <img src= { sinalVazio } alt="icone sinal"></img>
                <p className="Status-Text">Offline</p>
            </div>
        )
    }
}

export default Off;