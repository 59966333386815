function formatTime(timestamp){
        let time = new Date(timestamp*1000)
        var hours = time.getHours();
        var minutes = time.getMinutes();
        var day = time.getDate();
        var month = (time.getMonth()+1); 
        if (hours < 10) {
            hours = '0' + hours
        }
        if (minutes < 10) {
            minutes = '0' + minutes
        }
        if (day < 10) {
            day = '0' + day
        }
        if (month < 10) {
            month = '0' + month
        }
        return hours+":"+minutes+" em "+day+"/"+month+"/"+time.getFullYear()

}

export default formatTime;