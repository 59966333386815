import React from "react";
import sinalCheio from "./../../../assets/sinal-cheio.svg";
import "./Status.css";

class On extends React.Component{
    render(){
        return(
            <div className="Status-On">
                <img src= { sinalCheio } alt="icone sinal"></img>
                <p className="Status-Text">Online</p>
            </div>
        )
    }
}

export default On;